import React from "react";
import AOS from 'aos';
import Img from "gatsby-image";
import "./slider.scss";

export default class Slider extends React.Component {
    constructor() {
        super();
        this.state = {
            products: [
                'Aplikacje',
                'Sklepy internetowe',
                'Strony internetowe',
                'Systemy Sass',
                'Design systemy'
            ],
        }
    }

    componentDidMount(){
        this.aos = AOS;
        this.aos.init({
            duration: 500
        });
    }

    componentDidUpdate() {
        this.aos.refresh();
    }

    getProducts(){
        return this.state.products.map((product,index) => 
             <li key={index} className={`text--product`}>
                {product}
            </li>
        );
    }

    render() {
        return <div className="container" data-aos="fade-up">
            <div className="text-container">
                <div className="text-wrapper text-wrapper--text-right">
                    <span>{this.props.textLeft || 'Tworzymy'}</span>
                </div>
                <Img className="image-wrapper" fluid={this.props.image} loading="eager" critical={true} />
                <div className="text-wrapper">
                    <ul className={this.props.textRight ? 'flip disable-animation' : 'flip'}>
                        {this.props.textRight ? <li className="text--product">{this.props.textRight}</li> : this.getProducts()}  
                    </ul>
                </div>
            </div>
        </div>
    }
}